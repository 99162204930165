import StatePersister from './StatePersister';

export default class SessionStatePersister extends StatePersister {
  constructor() {
    super(sessionStorage);
    if (this.constructor === SessionStatePersister) {
      throw new TypeError('Abstract class "SessionStatePersister" cannot be instantiated directly.');
    }
  }
}
