import SessionStatePersister from '@/utils/browser/SessionStatePersister';

export default class CrudTablePersistentState extends SessionStatePersister {
  setNameSpace(namespace) {
    super.setNameSpace(`crudTable_${namespace}`);
  }
  getDefaultSearch(defaultFilters, filters) {
    const persistedSearch = super.getPersistedValue('Search') || defaultFilters;
    Object.assign(persistedSearch, filters);
    return persistedSearch;
  }
  getDefaultListQuery() {
    return super.getPersistedValue('ListQuery', {
      limit: 20,
      page: 1,
      sort: null
    });
  }
  persistListParams(listParameters) {
    if (!this.enabled) {
      return;
    }
    const listQuery = {
      limit: listParameters.query.limit,
      page: listParameters.query.page,
      sort: listParameters.query.sort
    };
    const search = {
      ...listParameters.query
    };
    delete search.condition;
    delete search.limit;
    delete search.page;
    delete search.sort;
    for (const propName in search) {
      if ((search[propName] !== 0 && !search[propName]) || search[propName].length === 0) {
        delete search[propName];
      }
    }
    super.persistValue('ListQuery', listQuery);
    super.persistValue('Search', search);
  }
  getCleanupFunc() {
    return super.getCleanupFunc(['Search', 'ListQuery']);
  }
}
